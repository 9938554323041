@font-face {
  font-family: 'Poppins';
  src: url('https://d3i3csyu9g9snt.cloudfront.net/fonts/Poppins-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('https://d3i3csyu9g9snt.cloudfront.net/fonts/Poppins-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('https://d3i3csyu9g9snt.cloudfront.net/fonts/Poppins-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Extra-Light';
  src: url('https://d3i3csyu9g9snt.cloudfront.net/fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Semi-Bold';
  src: url('https://d3i3csyu9g9snt.cloudfront.net/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Barlow-Condensed';
  src: url('https://d3i3csyu9g9snt.cloudfront.net/fonts/BarlowCondensed-Regular.ttf') format('truetype');
  font-display: swap;
}
